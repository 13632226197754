import {t,nt} from "@/util/http"
import config from "../config"
import qs from 'qs';

export default {

  /************************************ 基础路径 ****************************** */
  // 运营端路径
  serverUrl: config.server,
  
  /************************************ womusic ****************************** */
  // 发送登录验证码
  sendLoginCode: params => t.post("/womusic/sendLoginCode", params),
  // 校验登录验证码
  codeLogin: params => t.post("/womusic/codeLogin", params),
  // 彩铃收费产品订购（包月）
  onePointProductMon: params => t.post("/womusic/onePointProductMon", params),
  // 发送短信（联通）
  sendMsg: params => t.post("/womusic/sendMsg", params),
  // 查询已订购产品(免用户授权)
  qrySubedProductsNoToken: params => t.post("/womusic/qrySubedProductsNoToken", params),
  // 登录获取token
  login: params => t.post("/womusic/login", params),
  // 检查用户是否已订购
  check: params => t.post("/womusic/check", params),
  // 校验登录验证码
  sendLoginCodeNew: params => t.post("/womusic/sendLoginCodeNew", params),
}
